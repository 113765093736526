const SWEDISH_TRANSLATIONS = {
  
  'all': 'Alla',
  'send': 'Skicka',
  'search': 'Sök',
  'required': 'Obligatorisk',
  'compare': 'Jämför',
  'subject': 'Ämne',
  'description': 'Beskrivning',
  'all.cash.registers': 'Alla kassor',
  'message': 'Meddelande',
  'reply.send': 'Svar skickat!',
  'ticket.closed': 'Biljett stängd',
  'new.reply': 'Nytt svar',
  
  'downloading': 'Laddar ner',
  'downloading.file': 'Filen laddas ner',
  'loading': 'Laddar',
  'download': 'Ladda ner',
  'download.transaction.file': 'Ladda ned',
  'notification': 'Underrättelse',
  'notification.bar.tax': 'Kom ihåg att rapportera din kassa till Skatteverket!',
  'success': 'Framgång',
  'max.data': 'Kom ihåg: skillnaden mellan start- och slutdatum får inte överstiga 7 dagar.',
  
  'create.new.ticket': 'Skapa ärende',
  'your.tickets': 'Mina ärenden',
  'page.upgrade.send': 'Skicka',
  'page.upgrade.firstName': 'Förnamn',
  'page.upgrade.lastName': 'Efternamn',
  'page.upgrade.yourEmail': 'Din e-postadress',
  'page.upgrade.pro': 'Uppgradera till PRO',
  'page.upgrade.paragraph1': 'Sugen på mer data?',
  'page.upgrade.paragraph2': 'Ditt konto är idag av typen "{type}".',
  'page.upgrade.paragraph3': 'I ett BAS-konto får du en allmän översikt av din verksamhet samt möjligheten att kunna se transaktioner {days}',
  'page.upgrade.paragraph3.days': '10 dagar bakåt i tiden',
  'page.upgrade.paragraph4': 'Med en uppgradering till Dinkassa.se Pro får du tillgång till bland annat:',
  'page.upgrade.paragraph5': '{pro} medför en extra avgift baserat på antalet kassaregister.',
  'page.upgrade.paragraph6': 'Registrera ditt intresse för en uppgradering till {dinkassa} så återkommer vi till dig på den e-postadress som är registrerad till detta konto.',
  
  'page.upgrade.new': 'NY',
  'page.upgrade.features': 'Funktioner',
  'page.upgrade.list0': 'Tillgång till instrumentpanel',
  'page.upgrade.list1': 'Försäljningsrapporter såsom Topplista per produkt/kategori, Försäljning per dag, Försäljningsjämförelser m.fl.',
  'page.upgrade.list2': 'Kopior på Z-rapporter',
  'page.upgrade.list3': 'Periodrapporter för valfri datumperiod',
  'page.upgrade.list4': 'SIE4-filer (eller integration till externt bokföringssystem)',
  'page.upgrade.list5': 'Administration av artikelregister, kategorier, kundregister m.m.',
  'page.upgrade.list6': 'Bokföringskontoplan',
  'page.upgrade.list7': 'Transaktionsdetaljer 2 år bakåt i tiden',
  'page.upgrade.list8': 'Transaktionsdetaljer för 2 år bakåt i tiden',
  'page.upgrade.list9': 'Tillgång till företagets fakturor',
  
  'form.firstName.error.required': 'Förnamn krävs',
  'form.lastName.error.required': 'Efternamn krävs',
  'form.email.error.required': 'E-post krävs',
  'form.email.error.invalid': 'Ogiltig e-postadress',
  
  'date.from.cannot.exceed': 'Datum från får inte överstiga igår',
  'date.to.cannot.exceed': 'Datum till får inte överstiga igår',
  'date.to.before.date.from': 'Datum måste vara efter "Datum från"',
  
  'large.data': 'Ladda ner stora mängder data',
  'Preparing.data': 'Hämtar data',
  'form.numbers.only': 'Endast nummer',
  'four.numbers.only': 'Ange 4 siffror',
  'bookkeeping.helperText': 'beskrivning',
  
  'administration.item.saved': '"{item}" sparad!',
  'report.zreport.number': 'Z-rapport nr.',
  
  'difference': 'Skillnad',
  'change': 'Förändring',
  'button.save': 'Spara',
  'button.edit': 'Redigera',
  'button.close': 'Stäng',
  'button.remove': 'Ta bort',
  'button.cancel': 'Avbryt',
  'button.yes': 'Ja',
  'button.next': 'Nästa',
  'button.prev': 'Tillbaka',
  'confirmation': 'Bekräftelse',
  'confirmation.prompt': 'Är du säker på att du vill lämna sidan utan att spara?',
  'confirmation.confirm.text': 'Ja',
  'confirmation.cancel.text': 'Nej',
  'page.step': 'Steg {number}',
  'freshdesk.ticket.close.btn': 'AVSLUTA ÄRENDE',
  'contact.sales.header': 'Kontakta säljare',
  
  'name': 'Förnamn',
  'surname': 'Efternamn',
  
  'next.day': 'Nästa dag',
  'previous.day': 'Föregående dag',
  'next.week': 'Nästa vecka',
  'previous.week': 'Föregående vecka',
  'previous.two.weeks': 'Föregående två veckor',
  'next.two.weeks': 'Nästa två veckor',
  'previous.month': 'Föregående månad',
  'next.month': 'Nästa månad',
  
  'contact.form.description1': 'Använd detta formulär för att snabbt komma i kontakt med vår <b>säljavdelning</b>.',
  'contact.form.description2': 'Fyll i uppgifterna så hjälper vi dig så snart vi kan!',
  
  'menu.main.bug': 'Lämna feedback',
  'menu.main.contact.sales': 'Kontakta säljare',
  'invalid.date.format': 'Ogiltigt datumformat',
  'of.prev.period': 'förra perioden',
  
  'date.options.january': 'Januari',
  'date.options.february': 'Februari',
  'date.options.march': 'Mars',
  'date.options.april': 'April',
  'date.options.may': 'Maj',
  'date.options.june': 'Juni',
  'date.options.july': 'Juli',
  'date.options.august': 'Augusti',
  'date.options.september': 'September',
  'date.options.october': 'Oktober',
  'date.options.november': 'November',
  'date.options.december': 'December',
  
  'toolbar.account': 'Konto',
  'menu.account.licence.primary': 'Licens: {value}',
  'menu.account.account': 'Konto',
  'menu.account.licence.secondary': 'Tillgång till data fr.o.m.: {value}',
  'menu.account.log.out': 'Logga ut',
  
  'menu.main.overview': 'Översikt',
  
  'menu.main.reports': 'Rapporter',
  'menu.main.reports.comparisons': 'Försäljningsjämförelser',
  'menu.main.reports.sales-overview': 'Försäljningsöversikt',
  
  'menu.main.reports.top': 'Topplista',
  'menu.main.reports.top.category-sales': 'Försäljning per kategori',
  'menu.main.reports.top.product-sales': 'Försäljning per produkt',
  
  'menu.main.reports.z': 'Z-rapporter',
  'menu.main.reports.periodic': 'Periodrapporter',
  'menu.main.reports.periodic.info': 'inklusive X-rapporter',
  
  'menu.main.reports.sie4': 'Rapporter med stöd för SIE4 export',
  
  'menu.main.reports.other.payments': 'Betalningar',
  'menu.main.reports.other.payouts': 'Utbetalningar',
  'menu.main.reports.other.sales': 'Försäljning',
  'menu.main.reports.other.discounts': 'Rabatter',
  'menu.main.reports.other.giftcard': 'Presentkort',
  'menu.main.reports.other.errors': 'Felslag',
  'menu.main.reports.other.staff': 'Personalliggare',
  'menu.main.reports.other.waste': 'Svinn',
  
  'menu.main.transactions': 'Transaktionsdetaljer',
  
  'menu.main.administration': 'Administration',
  'menu.main.administration.working-hours': 'Arbetstider',
  'menu.main.administration.accounts': 'Konton',
  'menu.main.administration.customers': 'Kunder',
  'menu.main.administration.articles': 'Artikelregister',
  'menu.main.administration.categories': 'Kategorier',
  'menu.main.administration.subcategories': 'Bongkategorier',
  'menu.main.administration.dictionaries': 'Ordlistor',
  'menu.main.administration.vouchers': 'Presentkort',
  'menu.main.administration.bookkeeping': 'Bokföring (kontoplan)',
  
  'menu.main.settings': 'Inställningar',
  'menu.main.knowledge-portal': 'Kunskapsportalen',
  'menu.main.swedish-irs': 'Skatteverket',
  'menu.main.parsly': 'Parsly',
  'menu.main.esorder': 'ES Order Backoffice',
  'menu.main.freshdesk': 'Kundsupport',
  'menu.main.settings.machines': 'Kassamaskiner',
  'menu.main.settings.backup': 'Backuper',
  'menu.main.settings.invoices': 'Fakturor',
  
  'menu.main.about': 'Om {appName}',
  
  'page.login.link.about': 'Handla om',
  
  'page.about.header': 'Välkommen till dinkassa.se',
  'page.about.paragraph.1': 'Dinkassa.se är en online-tjänst som kan användas av dig som har {link1} från {link2}. Här kan du logga in och se online vad som händer i din verksamhet. Tjänsten fungerar för alla typer av verksamheter, t.ex. restauranger, caféer, frisörer och alla sorters butiker.',
  'page.about.paragraph.2': 'Under förutsättning att din kassa är uppkopplad mot Internet skickas information kontinuerligt vidare till vår serverhall via en säker, krypterad anslutning. (Din brandvägg behöver inte ändras eller öppnas på något vis.) Med hjälp av ditt personliga användarnamn och lösenord kan du logga in och följa försäljningen från vilken internetuppkopplad dator som helst. Om du driver flera företag eller har flera separata kassor kan hela försäljningen kontrolleras gemensamt eller separat för varje enskild kassa. Det finns ingen begränsning i antalet kassor som kan kopplas samman för att du ska få en total överblick av din verksamhet.',
  'page.about.paragraph.3': 'Med hjälp av dinkassa.se kan du både kontrollera och administrera din kassa, oavsett var du befinner dig. Om du vill, kan du även ge en inloggning till din redovisningsbyrå. Då kan din revisor gå in direkt och hämta den information han behöver för att göra t.ex. momsrapporter och bokslut. När du använder dinkassa.se får du automatiskt även en komplett backup av din kassa online. Du behöver inte längre vara rädd för att tappa information till din bokföring.',
  'page.about.paragraph.4': 'I ES Kassasystem ingår utan extra kostnad en grundlicens till dinkassa.se där du bl.a. kan följa de senaste dagarnas försäljning. För dig som vill gräva djupare i din verksamhet tillhandahåller vi mer avancerade funktioner och möjlighet att se historik längre bakåt i tiden.',
  'page.about.paragraph.5': 'Koppla upp dig mot dinkassa.se nu med detsamma. Du får total kontroll på din verksamhet och kan spara både tid och pengar!',
  'page.about.paragraph.6': 'Läs mer på {link} eller kontakta Erpato Europe AB på tel. {phone} eller {email}.',
  
  'page.user-settings.change-password.button': 'Ändra lösenord',
  'page.user-settings.details.title': 'Kontouppgifter',
  'page.user-settings.license.details': 'Licensdetaljer',
  'account.details.saved': 'Kontoinformationen sparat!',
  
  'page.user-settings.license.type': 'Licens: {version}',
  'page.user-settings.license.access': 'Tillgång till data {days} dagar bakåt i tiden (från {startDate})',
  
  'form.login.field.login.label': 'Användarnamn',
  'form.login.field.login.error.empty': 'Användarnamn krävs',
  'form.login.field.password.label': 'Lösenord',
  'form.login.field.password.error.empty': 'Lösenord krävs',
  'form.login.button.submit': 'Logga in',
  'form.login.wrong.credentials.error': 'Fel användarnamn och/eller lösenord',
  
  'form.required': 'Obligatoriskt',
  'form.user-settings.details.title': 'Kontouppgifter',
  'form.user-settings.details.field.username.label': 'Användarnamn',
  'form.user-settings.details.field.email.label': 'E-post',
  'form.user-settings.details.field.first-name.label': 'Förnamn',
  'form.user-settings.details.field.first-name.error.empty': 'Förnamn krävs',
  'form.user-settings.details.field.last-name.label': 'Efternamn',
  'form.user-settings.details.field.last-name.error.empty': 'Efternamn krävs',
  'form.user-settings.details.save': 'Spara',
  
  'form.standard.report.date.required': 'Datum krävs',
  'form.standard.report.date.max3.months.date.range': 'Maximalt datumintervall är 3 månader',
  
  'form.standard.transactions.date.max7.days.date.range': 'Max datumintervall är 7 dagar',
  
  'form.standard.transactions.date.max10.days.earlier': 'Välj datum från de senaste 10 dagarna',
  
  'form.user-settings.change-password.title': 'Byte av lösenord',
  'form.user-settings.change-password.field.old-password.label': 'Nuvarande lösenord',
  'form.user-settings.change-password.field.old-password.error.empty': 'Nuvarande lösenord krävs',
  'form.user-settings.change-password.field.new-password.label': 'Nytt lösenord',
  'form.user-settings.change-password.field.new-password.error.empty': 'Nytt lösenord krävs',
  'form.user-settings.change-password.field.new-password.error.same': 'Det nya lösenordet är detsamma som det nuvarande',
  'form.user-settings.change-password.field.confirm-password.label': 'Verifiera nytt lösenord',
  'form.user-settings.change-password.field.password.error.min.length': 'Minst 8 tecken',
  'form.user-settings.change-password.field.password.error.current': 'Det angivna lösenordet är felaktigt',
  'form.user-settings.change-password.field.confirm-password.error.empty': 'Verifiera nytt lösenord krävs',
  'form.user-settings.change-password.field.confirm-password.error.mismatch': 'Måste vara samma som det nya lösenordet',
  'form.user-settings.change-password.save': 'Spara',
  'form.transactions.receipts.and.invoices': 'Både kvitton & fakturor',
  'form.transactions.only.receipts': 'Endast kvitton',
  'form.transactions.only.invoices': 'Endast fakturor',
  'field.transaction.type': 'Överföringstyp',
  'my.tickets': 'Mina ärenden',
  'new.ticket': 'Nytt ärende',
  'freshdesk.new.ticket.content': 'Skapa nytt ärende',
  'freshdesk.create.ticket': 'Skapa ärende',
  'freshdesk.ticket': 'Biljett',
  'freshdesk.email': 'E-post',
  'freshdesk.placeholder': 'Beskriv ditt ärende så noggrant som möjligt',
  'freshdesk.send': 'Skapa ärende',
  'freshdesk.tickets': 'Mina ärenden',
  'freshdesk.ticket.sent': 'Ditt ärende har skapats. {break}Vi återkommer till dig så snart vi kan!',
  'freshdesk.description': '<b>Läs, redigera</b> eller <b>svara</b> på dina ärenden.',
  'freshdesk.description.closed': '<b>Läs </b>på dina ärenden.',
  'freshdesk.new.ticket.description1': 'Du kan använda detta formulär för att snabbt <b>skapa</b> ett supportärende.',
  'freshdesk.new.ticket.description2': 'Fyll i uppgifterna nedan så återkommer vi till dig så snart som möjligt. Du kan följa ditt ärende under <b>"Mina ärenden"</b>.',
  'freshdesk.statusBar.ticket': 'Ärende',
  'freshdesk.close.ticket': 'AVSLUTA ÄRENDET',
  'new.ticket.message': 'Ärende skapat',
  'freshdesk.header.closed': 'Stängda ärenden',
  'new.ticket.created': 'Vi hör av oss till dig så fort vi kan!',
  'freshdesk.no.tickets': 'Du har inga öppna ärenden',
  'freshdesk.delete.ticket': 'Är du säker på att du vill avsluta ärende #{ticketId}?',
  'freshdesk.cancel.button': 'Avbryt',
  'freshdesk.send.button': 'Skicka',
  'freshdesk.edit.ticket': 'Redigera ärende',
  'unknown': 'unknown',
  
  'freshdesk.status.open': 'Öppen',
  'freshdesk.status.pending': 'Väntande',
  'freshdesk.status.resolver': 'Lösare',
  'freshdesk.status.closed': 'Stängd',
  'freshdesk.ticket.me': 'JAG',
  'freshdesk.ticket.support': 'ES',
  'file.name': 'Filens namn',
  'size': 'Filstorlek',
  'date': 'Datum',
  
  'other.payment.type': 'Annat',
  'download.backup.files': 'Nedan kan du ladda ner dina backuper.',
  'search.backups': 'Sök säkerhetskopior',
  'page.machines.table.title': 'Kassamaskiner',
  'page.machines.table.header.name': 'Namn',
  'page.machines.table.header.version': 'Version',
  'page.machines.table.header.computer.name': 'Datornamn',
  'page.machines.table.header.last.connected': 'Senast uppkopplad',
  'page.machines.table.header.integration': 'Integrationer',
  'page.machines.table.header.fetch': 'Hämta från dinkassa.se till kassan (1)',
  'page.machines.table.header.clear': 'Rensa dinkassa.se (2)',
  'page.machines.table.sync.definition': 'Omsynkronisera objekt från dinkassa.se till kassan',
  'page.machines.table.clear.definition': 'Ta bort samtliga objekt på dinkassa.se-sidan (gäller ej transaktioner, fakturor etc.). Detta kommer att starta en omsynkronisering från kassan till dinkassa.se',
  
  'page.backup.table.title': 'Backuper',
  'page.backup.table.header.cashRegister': 'Kassa',
  'page.backup.table.header.fileName': 'Filnamn',
  'page.backup.table.tooltip.download.backup': 'Ladda ner backuper',
  'page.backup.table.header.description': 'Beskrivning',
  'page.backup.table.header.date': 'Datum',
  'page.backup.table.header.size': 'Storlek',
  
  'transaction.number': 'Transaktionsnummer',
  'cash.register': 'Kassadator',
  'date.time': 'Datum & tid',
  'employee.name': 'Personalnamn',
  'total.amount.vat': 'Totalt (inkl. moms)',
  'transaction.type': 'Transaktionstyp',
  'download.file': 'Nedladdning',
  
  'page.invoices.table.header.invoice.number': 'Faktura nr.',
  'page.invoices.table.header.invoice.date': 'Datum',
  'page.invoices.table.header.total.amount.excluding.vat': 'Total exkl. moms',
  'page.invoices.table.header.total.amount.including.vat': 'Total inkl. moms (att betala) ',
  'page.invoices.table.tooltip.download.invoice': 'Ladda ner faktura',
  'page.invoices.table.title': 'Fakturor',
  
  'page.dashboard.info': 'Vald starttid för verksamhetsdagen: {startTime}',
  'page.dashboard.salesThisWeek': 'Försäljning denna vecka',
  'page.dashboard.salesLastWeek': 'Försäljning föregående vecka',
  'page.dashboard.salesToday': 'Försäljning idag',
  'page.dashboard.salesYesterday': 'Försäljning i går',
  'page.dashboard.salesLastMonth': 'Försäljning föregående månad',
  'page.dashboard.top5ProductsLastMonth': 'Topp 5 produkter föregående månad',
  'page.dashboard.todaySales': 'Försäljning idag',
  'page.dashboard.top5CategoriesLastMonth': 'Topp 5 kategorier föregående månad',
  'page.dashboard.noDataAvailable': 'Ingen data tillgänglig',
  'page.dashboard.sales': 'Försäljning',
  'page.dashboard.overview': 'Översikt',
  'page.dashboard.ofWhichVAT': 'Varav moms',
  'page.dashboard.last7days': 'Senaste 7 dagarna',
  'page.dashboard.update': 'Läs in',
  
  'page.administration.addNewItem': 'Lägg till ny',
  'page.administration.excel': 'Excel',
  'page.administration.advancedOptions': 'Avancerade inställningar',
  'page.administration.addNew': 'Lägg till',
  'page.administration.deleteItem': 'Är du säker på att du vill ta bort denna artikel?',
  
  'page.administration.bookkeeping.editCategories': 'Hantera resultatkonton',
  'page.administration.bookkeeping.openIntegrations': 'Sammankoppla med bokföringssystem',
  'page.administration.bookkeeping.settingsUpdated': 'Bokföringsinställningar uppdaterade',
  'page.administration.bookkeeping.savingError': 'Fel vid lagring av bokföringsdata',
  
  'page.administration.bookkeeping.outgoingVAT': 'Utgående moms',
  'page.administration.bookkeeping.numberVAT': '{number}% moms',
  'page.administration.bookkeeping.salesNumberVAT': 'Försäljning, {number}% moms',
  
  'page.administration.bookkeeping.invoicing': 'Fordringar',
  'page.administration.bookkeeping.customerInvoicing': 'Kundfordringar',
  
  'page.administration.bookkeeping.item': 'Artikel',
  'page.administration.bookkeeping.oldValue': 'Gammalt värde',
  'page.administration.bookkeeping.newValue': 'Nytt värde',
  
  'page.administration.bookkeeping.skulder': 'Skulder',
  'page.administration.bookkeeping.unusedGiftcard': 'Ej inlösta presentkort',
  
  'page.administration.bookkeeping.otherItems': 'Övriga sidointäkter',
  'page.administration.bookkeeping.emptiedGiftcards': 'Tömda presentkort',
  
  'page.administration.bookkeeping.salesStandard': 'Försäljning, standard',
  'page.administration.bookkeeping.salesDutyfree': 'Försäljning, momsfri',
  'page.administration.bookkeeping.salesExcluded': 'Försäljning, exkludread',
  
  'page.administration.bookkeeping.cash': 'Kontant',
  'page.administration.bookkeeping.bankAccount': 'Bankkonto',
  'page.administration.bookkeeping.card': 'Kort',
  'page.administration.bookkeeping.coupon': 'Kupong',
  'page.administration.bookkeeping.other': 'Annat',
  'page.administration.bookkeeping.cashDifference': 'Kassadifferens',
  
  'page.administration.bookkeeping.payments': 'Betalningsmedel',
  'page.administration.bookkeeping.others': 'Övrigt',
  
  'page.administration.bookkeeping.roundingOre': 'Öresavrundning',
  'page.administration.bookkeeping.tips': 'Dricks',
  'page.administration.bookkeeping.cardType': 'Korttyp',
  'page.administration.bookkeeping.recyclingDeposit': 'Pant',
  
  'field.gift.card': 'Presentkort',
  'field.personal.nr': 'Personal nr.',
  'field.purchase.refill.gifts.cards': 'Köp / påfyllning av presentkort',
  'field.payment.gift.card': 'Betalning med presentkort',
  'field.deposit': 'Insättning',
  'field.withdrawal': 'Uttag',
  'field.include.invoices': 'Inkludera fakturor',
  'field.free.text': 'Fri-text',
  'field.cash.register': 'Kassa',
  'field.company.names': 'Företagsnamn',
  'field.topsales.show.number': 'Visa antal',
  'field.sales.overview.time.unit': 'Tidsenhet',
  
  'message.sent': 'Meddelandet har skickats',
  
  'sales.overview.column.antal': 'Antal transaktioner',
  'sales.overview.column.total': 'Totalt',
  'sales.overview.column.giftcards': 'Presentkort',
  'menu.main.settings.integrations': 'Integrationer',
  'sold.gift.cards.notification': 'Sålda presentkort är inkluderade inom "Totalt"',
  
  'menu.main.news': 'Nyheter',
  'date.field.date': 'Datum',
  'date.field.date.from': 'Fr.o.m. datum',
  'date.field.date.to': 'T.o.m. datum',
  'date.field.time.from': 'T.o.m. tid',
  'date.field.time.to': 'T.o.m. tid',
  'date.field.date.time.from': 'Fr.o.m.-datum & klockslag',
  'date.field.date.time.to': 'T.o.m.-datum & klockslag',
  'date.field.date.range': 'Datumintervall',
  
  'date.field.start.date.report': 'Fr.o.m. datum - Rapport {reportNumber}',
  'date.field.end.date.report': 'T.o.m. datum - Rapport {reportNumber}',
  
  'date.field.period.type': 'Period-typ',
  'time.unit.options.day': 'Dag',
  'time.unit.options.week': 'Vecka',
  'time.unit.options.month': 'Månad',
  'time.unit.options.quarter': 'Kvartal',
  'time.unit.options.year': 'År',
  'time.unit.options.hour': 'Timma',
  'time.unit.options.day.and.hour': 'Dag och timma',
  'time.unit.options.year.and.month': 'År och månad',
  'time.unit.options.quarter.hour': 'Kvart',
  'time.unit.options.half.hour': 'Halvtimme',
  'time.unit.options.hour.each.day': 'Timme varje dag',
  
  'date.options.today': 'Idag',
  'date.options.yesterday': 'Igår',
  'date.options.this.week': 'Denna vecka',
  'date.options.last.week': 'Föregående vecka',
  'date.options.last.two.weeks': 'Senaste två veckorna',
  'date.options.this.month': 'Denna månad',
  'date.options.last.month': 'Föregående månad',
  'date.options.last.two.months': 'Senaste två månaderna',
  'date.options.this.year': 'Detta år',
  'date.options.last.year': 'Föregående år',
  'date.options.last.two.years': 'Senaste två åren',
  
  'date.picker.cancel': 'Avbryt',
  'date.picker.clear': 'Klar',
  
  'zreports.title': 'Z-rapporter',
  'zreports.info': 'Här kan du ta en kopia på de z-rapporter som tidigare har genererats i kassan. Välj först vilken kassa som du är intresserad av. Välj därefter ett datumintervall och klicka på Sök. En lista på genererade z-rapporter visas på skärmen. Välj den z-rapport som du är intresserad av (klicka på ikonen bredvid z-rapport nr). Rapporten visas som pdf-fil i A4-format.',
  
  'page.periodicReport.header': 'Periodrapporter',
  'page.periodicReport.PDFreport.button': 'Öppna period rapport',
  'page.periodicReport.PDFreport.description': 'Öppna rapport för vald period. Rapporten visas som pdf-fil i A4-format.',
  'page.periodicReport.SIEexport.button': 'SIE-export',
  'page.periodicReport.SIEexport.description': 'Generera SIE-filer som används för att importera all information om försäljningen till ditt bokföringsprogram. SIE är ett standardiserat format som kan användas av alla vanliga bokföringsprogram. Spara filen på din hårddisk eller på ett USB-minne.',
  'page.periodicReport.Xreport.button': 'Öppna X-rapport',
  'page.periodicReport.Xreport.description': 'Generera en ny x-rapport från kassan i realtid. En pdf-fil i A4-format öppnas med en rapport från senaste dagsavslut fram till aktuell tidpunkt. OBS! att det kan förekomma en fördröjning på upp till 30 minuter, d.v.s. du kan se all försäljning som är gjord på kassan fram till för 30 minuter sedan.',
  'page.periodicReport.400.error': 'Rapporten kan inte öppnas ännu. Informationen som krävs för att öppna rapporten är ännu inte garanterat överförd från kassan till dinkassa.se. Se till att välja ett slutdatum för rapporten där transaktionerna har skapats och överförts till dinkassa.se. Detta betyder att man inte kan ange ett datum i framtiden.',
  'page.zreports.table.header.date': 'Datum',
  'page.zreports.table.header.zreports.number': 'Z-rapport nr.',
  'page.zreports.table.header.created.by': 'Skapad av',
  'page.zreports.table.tooltip.print.copy': 'Skriv ut kopia',
  
  'no.data': 'Ingen data',
  'report.title.IncludingVat': '(inkl. moms)',
  'report.title.ExcludingVat': '(exkl. moms)',
  'report.title.Total': 'Totalt',
  'report.title.EmployeeTimeWorked': 'Arbetstid',
  
  'report.transaction.sale': 'Köp',
  'report.transaction.return': 'Retur',
  
  'report.title.PayoutPerProduct': 'Utbetalningar per product',
  'report.title.PayoutPerCategory': 'Utbetalningar per kategori',
  
  'report.title.comparisons': 'Försäljningsjämförelser',
  'report.title.PaymentsPerDay': 'Betalningar per dag',
  'report.title.PaymentsPerDayAndEmployee': 'Betalningar per dag och anställd',
  'report.title.PaymentsTotal': 'Betalningar, totalt',
  'report.title.CashGuardTransactionLog': 'CashGuard-transaktioner',
  'report.title.averageSale': 'Snittförsäljning',
  'report.title.SalesAndPaymentsPerCategory': 'Försäljning per kategori och betalsätt',
  'report.title.SalesPerDate': 'Försäljning per dag',
  'report.title.SalesPerCampaign': 'Försäljning per kampanj',
  'report.title.SalesPerCategory': 'Försäljning per kategori',
  'report.title.SalesPerCategoryAndEmployee': 'Försäljning per kategori och personal',
  'report.title.SalesPerCategoryAndCustomer': 'Försäljning per kategori och kund',
  'report.title.SalesPerSupplier': 'Försäljning per leverantör',
  'report.title.SalesPerSupplierAndProduct': 'Försäljning per leverantör och produkt',
  'report.title.SalesPerProduct': 'Försäljning per produkt',
  'report.title.SalesPerProductAndEmployee': 'Försäljning per produkt och anställd',
  'report.title.EmployeeWorkTimeCorrectedLogs': 'Korrigerade in/ut-loggningar',
  'report.title.InventoryItemStockChangesList': 'Lagerhistorik',
  'report.title.GiftCardTransactionLog': 'Presentkortshistorik',
  'report.title.GiftCardBalanceChangeLog': 'Presentkortssaldohistorik',
  'report.title.GiftCardBalancePerDate': 'Presentkortsvärdehistorik',
  'report.title.DiscountPerEmployeeReport': 'Rabatter per anställd',
  'report.title.DiscountReport': 'Rabatter per typ',
  'report.title.InvalidRegistrationsLog': 'Registrerade felslag',
  'report.title.InvalidRegistrationsByEmployee': 'Registrerade felslag per anställd',
  'report.title.InvalidRegistrationsByDayAndEmployee': 'Registrerade felslag per anställd och dag',
  'report.title.WasteRegistrationsLog': 'Registrerat svinn',
  'report.title.WasteRegistrationsByEmployee': 'Registrerat svinn per anställd',
  'report.title.WasteRegistrationsByDayAndEmployee': 'Registrerat svinn per anställd och dag',
  'report.title.EmployeeLoggedInPerDayLog': 'Tid inloggad per dag',
  'report.title.EmployeeLoggedInPerMonthLog': 'Tid inloggad per månad',
  'report.title.EmployeeLoggedInLog': 'Tider inloggad',
  'report.title.TransactionList': 'Transaktioner',
  'report.title.TransactionsSieDayList': 'Transaktioner per dag och konto',
  'report.title.TransactionsSieZReportList': 'SIE-export',
  'report.title.NoDataFound': 'Ingen data hittades',
  'report.title.NoDataFoundForGivenRange': 'Ingen data tillgänglig för angiven tidsperiod',
  'report.title.preparing-file': 'Förbereder din fil',
  
  'report.transaction.Items': 'Kvittodata',
  'report.transaction.InventoryItemDescription': 'Produktnamn',
  'report.transaction.TotalAmountIncludingVat': 'Pris (inkl. moms)',
  'report.transaction.TotalVat': 'Moms',
  'report.transaction.Quantity': 'Antal',
  'report.transaction.Payments': 'Betalningar',
  'report.transaction.Amount': 'Totalsumma',
  'report.transaction.Provider': 'Betalmedel',
  'report.transaction.Card': 'Kort',
  'report.transaction.Cash': 'Kontant',
  'report.transaction.Invoice': 'Faktura',
  'report.transaction.Other': 'Annat',
  
  'report.chart.Sum': 'Summa',
  'report.chart.Date': 'Datum',
  'report.chart.Product': 'Produkt',
  'report.chart.Category': 'Kategori',
  'report.chart.Employee': 'Anställd',
  'report.chart.Campaign': 'Kampanj',
  'report.chart.Customer': 'Kund',
  'report.chart.Quantity': 'Antal',
  'report.chart.TotalQuantity': 'Totalt antal',
  'report.chart.ArticleNumber': 'Artikelnr.',
  'report.chart.Description': 'Beskrivning',
  'report.chart.PersonalName': 'Personalnamn',
  'report.chart.Type': 'Typ',
  'report.chart.PurchasePriceExclVatPcs': 'Inköpspris exkl. moms/st.',
  'report.chart.TotalPurchasePriceExclVat': 'Totalt inköpspris exkl. moms',
  'report.chart.Supplier': 'Leverantör',
  'report.chart.EmployeeNumber': 'Anställd#',
  'report.chart.EmployeeID': 'Anställd ID',
  'report.chart.EmployeeName': 'Anställd-namn',
  'report.chart.Name': 'Namn',
  'report.chart.ItemOf': 'Vara',
  'report.chart.TotalPrice': 'Totalt pris',
  'report.chart.Cause': 'Orsak',
  'report.chart.SalesOverview': 'Försäljning',
  'report.chart.AmountOfReturnedItems': 'Mängd returnerade varor',
  'report.chart.items': 'varor',
  'report.chart.NumberOfReturnedItems': 'Antal returnerade varor',
  'report.chart.GiftCardsQuantity': 'Antal presentkort',
  'report.chart.ActiveGiftCardsBalance': 'Saldo för aktiva presentkort',
  'report.chart.InactiveGiftCardsBalance': 'Saldo för inaktiva presentkort',
  'report.chart.SalesTotalVat': 'Försäljning moms',
  'report.chart.SalesInclVat': 'Försäljning inkl. moms',
  'report.chart.SalesExclVat': 'Försäljning exkl. moms',
  'report.chart.NetQuantity': 'Nettomängd varor',
  'report.chart.NetAmount': 'Netto antal varor',
  'report.chart.ReturnsInclVat': 'Returer inkl. moms',
  'report.chart.ReturnsExclVat': 'Returer exkl. moms',
  'report.chart.ReturnsSumVat': 'Returer moms',
  'report.chart.NetReturnedItems': 'Antal returnerade varor',
  'report.chart.NetSalesInclVat': 'Nettoförsäljning inkl. moms',
  'report.chart.NetSalesExclVat': 'Nettoförsäljning exkl. moms',
  'report.chart.NetSalesSumVat': 'Nettoförsäljning moms',
  'report.chart.PurchasePriceOfSoldGoodsExclVat': 'Inköpspris sålda varor exkl. moms',
  'report.chart.PurchasePriceOfReturnedGoodsExclVat': 'Inköpspris returnerade varor exkl. moms',
  'report.chart.NetPurchasePriceOfSoldGoodsExclVat': 'Inköpspris netto exkl. moms',
  'report.chart.TotalProfitExclVat': 'Total vinst exkl. moms',
  'report.chart.TotalPriceInclVat': 'Totalt pris inkl. moms',
  'report.chart.TotalProfit': 'Total vinst %',
  'report.chart.CreatedBy': 'Skapad av',
  'report.chart.Transaction': 'Transaktion',
  'report.chart.InvoiceNumber': 'Fakturanr.',
  'report.chart.NumberOfSoldGoods': 'Mängd sålda varor',
  'report.chart.StockBalance': 'Befintligt lagersaldo',
  'report.chart.SalesInclVAT': 'Försäljning inkl. moms',
  'report.chart.SalesExclVAT': 'Försäljning exkl. moms',
  'report.chart.SalesVAT': 'Försäljning moms',
  'report.chart.ReturnsInclVAT': 'Returer inkl. moms',
  'report.chart.ReturnsExclVAT': 'Returer exkl. moms',
  'report.chart.ReturnsVAT': 'Returer moms',
  'report.chart.NumberOfDiscounts': 'Antal rabatter',
  'report.chart.TotalSalesExclVAT': 'Total försäljning exkl. moms',
  'report.chart.DiscountType': 'Rabatt typ',
  'report.chart.ReceiptsQuantity': 'Antal kvitton',
  'report.chart.ReturnsQuantity': 'Antal returer',
  
  'report.toolbar.columns': 'Kolumner',
  'report.toolbar.filters': 'Filter',
  'report.toolbar.density': 'Densitet',
  'report.footer.rowsPerPage': 'Rader per sida:',
  'report.footer.labelDisplayedRows': '{from}-{to} av {count}:',
  
  'report.toolbar.find.column': 'Sök kolumn',
  'report.toolbar.column.title': 'Kolumntitel',
  'report.toolbar.column.hide.all': 'GÖM ALLA',
  'report.toolbar.column.show.all': 'VISA ALLA',
  'report.toolbar.filter.operators': 'Filtreringstyp',
  'report.toolbar.filter.value': 'Värde',
  'report.toolbar.filter.filter.value': 'Filtreringsvärde',
  'report.toolbar.filter.contains': 'innehåller',
  'report.toolbar.filter.equals': 'är lika med',
  'report.toolbar.filter.starts.with': 'börjar med',
  'report.toolbar.filter.ends.with': 'slutar med',
  'report.toolbar.filter.is.empty': 'är tom',
  'report.toolbar.filter.is.not.empty': 'är inte tom',
  'report.toolbar.density.compact': 'Kompakt',
  'report.toolbar.density.standard': 'Standard',
  'report.toolbar.density.comfortable': 'Komfortabel',
  'report.toolbar.show.filters': 'Visa filter',
  'report.toolbar.export': 'Exportera',
  'report.toolbar.download.csv': 'Ladda ned CSV',
  'report.toolbar.download': 'Ladda ned',
  'report.toolbar.filter.column': 'Kolumn',
  
  'report.sie4.type': 'Transaktionstyp',
  'report.sie4.invoice': 'Kvitto/Faktura',
  'report.sie4.number': 'Transaktionsnummer',
  'report.sie4.datum': 'Datum & tid',
  'report.sie4.employee.name': 'Personalnamn',
  'report.sie4.employee.number': 'Anställningsnr.',
  'report.sie4.row.type': 'Radtyp',
  'report.sie4.payment.type': 'Betalningstyp',
  'report.payment.type.cash': 'Kontant (betalning)',
  'report.payment.type.card': 'Kort (betalning)',
  'report.payment.type.swish': 'Swish (betalning)',
  'report.payment.identifier': 'Terminal',
  'report.sie4.amount.paid': 'Summa betalt',
  'report.sie4.giftcard.number': 'Presentkortsnummer',
  'report.sie4.category': 'Kategori',
  'report.sie4.item.name': 'Beskrivning',
  'report.sie4.quantity': 'Antal',
  'report.sie4.total.incl.VAT': 'Totalt pris inkl. moms',
  'report.sie4.total.excl.VAT': 'Totalt pris exkl. moms',
  'report.sie4.total.VAT': 'Total moms',
  'report.sie4.VAT': 'Momssats (%)',
  'report.sie4.total.discount.incl.VAT': 'Total rabatt inkl. moms',
  'report.sie4.total.purchase.excl.VAT': 'Totalt inköpspris exkl. moms',
  'report.sie4.total.profit.excl.VAT': 'Total vinst exkl. moms',
  'report.sie4.payment.account.number': 'Kontonr betalmedel',
  'report.sie4.item.account.number': 'Kontonr artikel',
  'report.sie4.excluded.item': 'Exkluderad registrering',
  'report.sie4.card.type': 'Korttyp',
  'report.sie4.excluded.yes': 'Ja',
  'report.sie4.excluded.no': 'Nej',
  'report.payment': 'Betalmedel',
  
  'administration.field.itemName': 'Artikelnamn',
  'administration.field.category': 'Kategori',
  'administration.field.visibleOnSalesMenu': 'Visa i försäljningsmenyn',
  'administration.field.buttonColor': 'Knappfärg',
  'administration.field.productCode': 'Artikelnummer',
  'administration.field.barCode': 'Streckkod',
  'administration.field.barCode.withNumber': 'Extra streckkod för artikel {barcodeNumber}',
  'administration.field.discount': 'Rabatt',
  'administration.field.salesStop': 'Säljstopp',
  'administration.field.salesStop.helperText': 'Kryssa i för att stoppa försäljning av artikeln',
  'administration.field.quantityInStockCurrent': 'Antal i lager',
  'administration.field.priceIncludingVat': 'Pris (inkl. moms)',
  'administration.field.vatPercentage': 'Moms',
  'administration.field.salesMenuSorter': 'salesMenuSorter',
  'administration.field.accountingType': 'Betaltyp',
  'administration.field.pickupPriceIncludingVat': 'Hämtpris (ta med) för artikeln',
  'administration.field.purchasePriceExcludingVat': 'Inköpspris i SEK för artikeln',
  'administration.field.specialFunctionType': 'Specialfunktion',
  'administration.field.specialFunctionType.helperText': 'Speciell händelse som inträffar när artikeln används',
  'administration.field.lastPriceChangeDateTime': 'Senaste prisförändring',
  'administration.field.LastModifiedDateTime': 'Senast ändrad Datum & Tid',
  'administration.field.printBongOnBongPrinter': 'Bong till Printer{printerNumber}',
  'administration.field.onlyPrintBongIfExternalOrder': 'Bong om weborder',
  'administration.field.bongCategoryName': 'Bongkategori',
  'administration.field.supplierName': 'Leverantör av artikeln',
  'administration.field.dictionary': 'Ordlista',
  'administration.field.itemInfo': 'Artikelinfo',
  'administration.field.itemInfo.helperText': 'Detaljerad information om artikeln',
  'administration.field.displayItemInOtherCategory': 'Visa i extra kategori',
  'administration.field.priceCalculationType': 'priceCalculationType',
  'administration.field.percentMarkupOnPurchasePrice': 'Prispålägg i %',
  'administration.field.comparisonPriceFactor': 'Jämförelsepris, omr. faktor',
  'administration.field.comparisonPriceUnit': 'Jämförelsepris, namn',
  'administration.field.priceUnit': 'Jämförelsepris, enhet',
  'administration.field.recyclingDepositAmountIncludingVat': 'Pant',
  'administration.field.quantityInStockWarningLevel': 'Varningsnivå (varulager)',
  'administration.field.multiPriceItem': 'multiPriceItem',
  'administration.field.multiPriceListId': 'multiPriceListId',
  'administration.field.fixedQuantity': 'fixedQuantity',
  
  'administration.field.recyclingDepositAmountIncludingVat.helperText': 'Dryckespant i SEK för artikeln',
  
  'day.options.monday': 'Måndag',
  'day.options.tuesday': 'Tisdag',
  'day.options.wednesday': 'Onsdag',
  'day.options.thursday': 'Torsdag',
  'day.options.friday': 'Fredag',
  'day.options.saturday': 'Lördag',
  'day.options.sunday': 'Söndag',
  'day.options.not.specified': '- Ej angiven -',
  
  'administration.field.name': 'Namn',
  'administration.field.categoryName': 'Kategorinamn',
  'administration.field.defaultVatPercentage': 'Moms',
  'administration.field.buttonColorName': 'Knappfärg',
  'administration.field.displaySubCategoryInventoryItems': 'Visa underkategorier',
  'administration.field.accountNumber': 'Kontonummer',
  'administration.field.parentCategoryName': 'Huvudkategori',
  'administration.field.allowDiscount': 'Tillåt rabatter',
  'administration.field.allowTakeaway': 'Tillåt avhämtning',
  'administration.field.onlyAllowCategories': 'Huvudkategori utan artiklar',
  'administration.field.defaultItemColor': 'Standard knappfärg',
  'administration.field.defaultBongCategoryName': 'Standard bongkategori',
  'administration.field.salesMenuColumnCount': 'SalesMenuColumnCount',
  'administration.field.salesMenuRowCount': 'SalesMenuRowCount',
  
  'administration.field.weight': 'Weight',
  'administration.field.startTime': 'Start Time',
  'administration.field.endTime': 'End Time',
  'administration.field.startDayOfWeek': 'Start Day Of Week',
  'administration.field.endDayOfWeek': 'End Day Of Week',
  'administration.field.startDate': 'Start Date',
  'administration.field.endDate': 'End Date',
  
  'administration.field.sieExportText': 'Sie Export Text',
  
  'administration.field.customerCode': 'Kundnummer',
  'administration.field.customerName': 'Kundnamn',
  'administration.field.emailAddress': 'E-post',
  'administration.field.organisationalNumber': 'Org. nummer',
  'administration.field.contactName': 'Kontaktperson',
  'administration.field.phoneNumber': 'Telefonnummer {number}',
  'administration.field.streetAddress': 'Gatuadress',
  'administration.field.zipCode': 'Postnummer',
  'administration.field.city': 'Stad',
  'administration.field.country': 'Land',
  'administration.field.internalComments': 'Intern kommentar',
  'administration.field.paymentTermsDays': 'Betalningsvillkor (dagar)',
  'administration.field.discountInPercent': 'Rabatt i %',
  
  'administration.field.sortingWeight': 'Sorteringsvikt',
  'administration.field.colorInTableMap': 'Färg i bordskarta',
  
  'administration.field.word': 'Bong info-ord',
  'administration.field.type': 'Type',
  'administration.field.fontSize': 'Teckenstorlek',
  'administration.field.newLine': 'Radbrytning',
  
  'administration.field.giftCardCode': 'Kortnummer',
  'administration.field.balance': 'Saldo',
  'administration.field.validFromDateTime': 'Giltig från',
  'administration.field.validToDateTime': 'Giltig till',
  'administration.field.isActivated': 'Aktiverad',
  'administration.field.isDeactivated': 'Is deactivated',
  'administration.field.isEnabled': 'Inaktiverad',
  'administration.field.description': 'Beskrivning',
  
  'pagination.of': 'av',
  'pagination.hits': 'träffar',
  
  'administration.field.word.helperText': 'Namn på knapp i rutan för "Bong info" i försäljningsmenyn',
  'administration.field.sortingWeight.helperText': 'Sorteringsvikt för placering på köksbong',
  'administration.field.dictionary.helperText': 'Namn på ordlista',
  'administration.field.fontSize.helperText': 'Teckenstorlek för "Bong info"-ordet',
  'administration.field.newLine.helperText': 'Kryssa i om nästa "Bong info"-ord skall synas på ny rad',
  
  'administration.field.nameBongCategory.helperText': 'Bongkategori för artikeln',
  'administration.field.colorInTableMap.helperText': 'Färg för bongkategori i bordskarta för "Begär fram"',
  
  'administration.field.categoryName.helperText': 'Namn på kategori',
  'administration.field.defaultVatPercentage.helperText': 'Standard momssats',
  'administration.field.buttonColorName.helperText': 'Färg på kategoriknapp',
  'administration.field.displaySubCategoryInventoryItems.helperText': 'Visa produkter inom underkategorier under denna kategori',
  'administration.field.accountNumber.helperText': 'Kontonummer för bokföring',
  'administration.field.parentCategoryName.helperText': 'Namn på huvudkategori',
  'administration.field.allowDiscount.helperText': 'Tillåt eller förbjud rabatter',
  'administration.field.allowTakeaway.helperText': 'Tillåt eller förbjud avhämtning',
  'administration.field.onlyAllowCategories.helperText': 'Använd som huvudkategori utan artiklar',
  'administration.field.defaultItemColor.helperText': 'Standardfärg för nya artiklar',
  'administration.field.defaultBongCategoryName.helperText': 'Standard bongkategori för nya artiklar',
  
  'administration.field.giftCardCode.helperText': 'Kortnummer för presentkortet',
  'administration.field.customerCode.helperText': 'Kundnummer för kund',
  'administration.field.balance.helperText': 'Saldo för presentkortet',
  'administration.field.validFromDateTime.helperText': 'Från-datum för presentkortets giltighetstid',
  'administration.field.validToDateTime.helperText': 'Till-datum för presentkortets giltighetstid',
  'administration.field.isActivated.helperText': 'Ikryssad om kortet är aktiverat',
  'administration.field.isDeactivated.helperText': 'Ikryssad om kortet är inaktiverat',
  'administration.field.isEnabled.helperText': 'Checked if giftcard is enable',
  
  'administration.field.customerName.helperText': 'Kundnummer för kund',
  'administration.field.organisationalNumber.helperText': 'Organsationsnummer - endast siffror',
  'administration.field.contactName.helperText': 'Namn på kontaktperson',
  'administration.field.phoneNumber.helperText': 'Telefonnummer {number} - endast siffror',
  'administration.field.streetAddress.helperText': 'Kortnummer',
  'administration.field.zipCode.helperText': 'Postnummer för kund - endast siffror',
  'administration.field.city.helperText': 'Hemstad för kund',
  'administration.field.country.helperText': 'Hemland för kund',
  'administration.field.discountInPercent.helperText': 'Fast rabatt för kund (vid fakturering/köp med medlemsskap)',
  'administration.field.internalComments.helperText': 'Interna kommentarer synlig för personal vid fakturering',
  'administration.field.paymentTermsDays.helperText': 'Antal dagar till betalning från faktureringsdatum',
  
  'administration.field.useStandardAccount': 'Använd standardkonto',
  
};

export default SWEDISH_TRANSLATIONS;
